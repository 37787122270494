const config = {
  CONFIG_APP_VERSION: "1.3.2",

  CONFIG_AUTH_TOKEN_KEY: process.env.REACT_APP_AUTH_TOKEN_KEY,
  CONFIG_SESSION_DAYS: process.env.REACT_APP_SESSION_DAYS,
  /* "test" | "qa" |"development" | "production" | "production_debug" */
  CONFIG_APP_ENV: process.env.REACT_APP_ENV,

  CONFIG_APP_URL: process.env.REACT_APP_URL,
  CONFIG_IH_URL: process.env.REACT_APP_IH_URL,
  CONFIG_APP_JWT_API_URL: process.env.REACT_APP_API_URL,

  CONFIG_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,

  CONFIG_STRIPE_STUDENT_COUPON_ID:
    process.env.REACT_APP_STRIPE_STUDENT_COUPON_ID,

  // hyper-react template config
  API_URL: process.env.REACT_APP_API_URL,

  /* "BoothMate web" | "BoothMate" | "Interpreters' Help" */
  CONFIG_APP_NAME: process.env.REACT_APP_NAME,
  /* "windows" | "linux" |"mac" | "web" */
  CONFIG_APP_BUILD: process.env.REACT_APP_BUILD,
  CONFIG_REALM_APP_ID: process.env.REACT_APP_REALM_APP_ID,
  /* "web" | "node" | "react-native" */
  CONFIG_REALM_SDK: process.env.REACT_APP_REALM_SDK,
  /* "all" | "trace" | "debug" | "detail" | "info" | "warn" | "error" | "fatal" | "off" */
  CONFIG_REALM_SYNC_LOG_LEVEL: process.env.REACT_APP_REALM_SYNC_LOG_LEVEL,
  CONFIG_REALM_SALT: process.env.REACT_APP_REALM_ENCRYPTION_KEY,

  CONFIG_TL_AUTH_KEY: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY,

  CONFIG_TL_TRANSCRIBE_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_TRANSCRIBE_TEMPLATE_ID,
  CONFIG_TL_TRANSCRIBE_AND_SAVE_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_TRANSCRIBE_AND_SAVE_TEMPLATE_ID,
  CONFIG_TL_TRANSCRIBE_AND_SRT_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_TRANSCRIBE_AND_SRT_TEMPLATE_ID,
  CONFIG_TL_TRANSCRIBE_AND_SRT_AND_SAVE_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_TRANSCRIBE_AND_SRT_AND_SAVE_TEMPLATE_ID,

  CONFIG_TL_UPLOADS_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_UPLOADS_TEMPLATE_ID,
  CONFIG_TL_CELL_IMAGES_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_CELL_IMAGES_TEMPLATE_ID,
  CONFIG_TL_CELL_AUDIOS_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_CELL_AUDIOS_TEMPLATE_ID,
  CONFIG_TL_CELL_VIDEOS_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_CELL_VIDEOS_TEMPLATE_ID,
  CONFIG_TL_CELL_FILES_TEMPLATE_ID:
    process.env.REACT_APP_TRANSLOADIT_CELL_FILES_TEMPLATE_ID,

  CONFIG_AWS_S3_BUCKET: process.env.REACT_APP_AWS_S3_BUCKET,
  CONFIG_AWS_S3_BUCKET_REGION: process.env.REACT_APP_AWS_S3_BUCKET_REGION,

  CONFIG_CYPRESS_PROJECT_ID: process.env.REACT_APP_CYPRESS_PROJECT_ID,

  CONFIG_PRO_PLAN_PRODUCT_ID: process.env.REACT_APP_PRO_PLAN_PRODUCT_ID,
  CONFIG_PRO_PLAN_TRIAL_DAYS: process.env.REACT_APP_PRO_PLAN_TRIAL_DAYS,
  CONFIG_PRO_PLAN_DAILY_PRICE_ID: process.env.REACT_APP_PRO_PLAN_DAILY_PRICE_ID,
  CONFIG_PRO_PLAN_MONTHLY_PRICE_ID:
    process.env.REACT_APP_PRO_PLAN_MONTHLY_PRICE_ID,
  CONFIG_PRO_PLAN_YEARLY_PRICE_ID:
    process.env.REACT_APP_PRO_PLAN_YEARLY_PRICE_ID,

  CONFIG_GROUP_PLAN_PRODUCT_ID: process.env.REACT_APP_GROUP_PLAN_PRODUCT_ID,
  CONFIG_GROUP_PLAN_TRIAL_DAYS: process.env.REACT_APP_GROUP_PLAN_TRIAL_DAYS,
  CONFIG_GROUP_PLAN_DAILY_PRICE_ID:
    process.env.REACT_APP_GROUP_PLAN_DAILY_PRICE_ID,
  CONFIG_GROUP_PLAN_MONTHLY_PRICE_ID:
    process.env.REACT_APP_GROUP_PLAN_MONTHLY_PRICE_ID,
  CONFIG_GROUP_PLAN_YEARLY_PRICE_ID:
    process.env.REACT_APP_GROUP_PLAN_YEARLY_PRICE_ID,
};

export default config;
